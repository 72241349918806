/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          contactEmail
        }
      }
    }
  `)

  let footerRef = React.useRef(null)

  const scrollToBottom = () => footerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })    

  return (
    <>
      <div className="bg-white-lightest" >
        <div className="px-4 md:px-16 mx-auto" style={{'maxWidth': '1440px'}}>
          <Header siteTitle={data.site.siteMetadata?.title || `Hello`} scrollToBottom={scrollToBottom} />
          <main className="grid grid-cols-5 gap-x-6 px-6">{children}</main>
          <Footer forwardedRef={footerRef} contactEmail={data.site.siteMetadata?.contactEmail || `Hello`} />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
