import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle, scrollToBottom }) => (
  <header>
    <div className="py-8 grid lg:grid-cols-5 px-6">
      <h6 className="font-bold lg:col-start-3 text-center transition-colors duration-300">
        <Link to="/" className="text-black-dark hover:text-gray-default">{siteTitle}</Link>
      </h6>
      <div className="col-span-2 justify-end content-center hidden pt-0.5 lg:flex">
        <h6 className="ml-8 text-sm leading-6 transition-colors duration-100">
          <Link to="/" className="text-black-dark hover:text-gray-default">Work</Link>
        </h6>
        <h6 className="ml-8 text-sm leading-6 transition-colors duration-100">
          <Link to="/about" className="text-black-dark hover:text-gray-default">About</Link>
        </h6>
        <h6 className="ml-8 text-sm leading-6 cursor-pointer text-black-dark hover:text-gray-light transition-colors duration-100" onClick={() => scrollToBottom()}>Contact</h6>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
