import * as React from "react"
import PropTypes from "prop-types"

const Footer = ({ contactEmail, forwardedRef }) => (
    <footer className="grid grid-cols-5 py-6 gap-x-6 px-6" ref={forwardedRef}>
        <h2 className={"col-span-5 col-start-1 mt-12 sm:mt-24 md:mt-36 lg:mt-36 sm:col-span-3 sm:col-start-2 text-2xl md:text-3xl lg:text-4xl"}>Want to create something that matters?</h2>
        <h5 className={"col-span-5 col-start-1 mt-1 md:mt-4 sm:col-span-3 sm:col-start-3 text-base md:text-lg lg:text-xl"}>{"Drop me a message at " + contactEmail}</h5>
        <h5 className={"col-span-5 col-start-1 mt-24 sm:mt-36 md:mt-48 lg:mt-64 sm:col-span-3 sm:col-start-3 text-base md:text-lg lg:text-xl"}>Designed and developed by Leonard Lutz. All rights reserved.</h5>
  </footer>
)

Footer.propTypes = {
            contactEmail: PropTypes.string,
}

Footer.defaultProps = {
            siteTitle: ``,
}

export default Footer
